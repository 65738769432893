@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import '~vuelayers/lib/style.css';
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.snow.css';

@import 'components/kit/vendors/antd/style';
@import 'components/kit/vendors/bootstrap/style';
@import 'components/kit/vendors/nprogress/style';
@import 'components/kit/vendors/chartist/style';
@import 'components/kit/vendors/chartist-tooltip-plugin/style';
@import 'components/kit/vendors/perfect-scrollbar/style';
@import 'components/kit/vendors/quill/style';

@import 'components/kit/core/style';
@import 'components/styles/style';

.ant-form-item {
	margin-bottom: 10px !important;	
}

.ant-form-item-label {
	padding-bottom: 0 !important;
}

[data-kit-theme="default"] .ant-form-vertical .ant-form-item-label {
	line-height: 2.5 !important;
}

.ant-form-item-required {
	margin-bottom: 0 !important;
}

[data-kit-theme="default"] .ant-table-thead > tr > th, [data-kit-theme="default"] .ant-table-tbody > tr > td {
    padding: 5px 15px;
    overflow-wrap: break-word;
}

div.ant-form-item-label {
	height: 30px;
}

.ant-form-item {
	margin-bottom: 15px;
}

.pointer {
	cursor: pointer !important;
}

.hrText {
	line-height: 1em;
	position: relative;
	outline: 0;
	border: 0;
	color: $primary;
	text-align: center;
	height: 1.5em;
	opacity: 0.7;
	&:before {
		content: '';
		// use the linear-gradient for the fading effect
		// use a solid background color for a solid bar
		background: linear-gradient(to right, transparent, $primary, transparent);
		position: absolute;
		left: 0;
		top: 50%;
		width: 100%;
		height: 1px;
	}
	&:after {
		content: attr(data-content);
		position: relative;
		display: inline-block;
		color: $primary;

		padding: 0 0.5em;
		line-height: 1.5em;
		// this is really the only tricky part, you need to specify the background color of the container element...
		color: $primary;
		background-color: #fcfcfa;
	}
}

.hrDotted {
	border-top: 1px dashed $gray-6;
}

.p0 {
	padding: 0 !important;
}

.pt10 {
    padding-top: 10px !important;
}

.pt15 {
    padding-top: 15px !important;
}

.pt20 {
    padding-top: 20px !important;
}

.pt25 {
    padding-top: 25px !important;
}

.pt30 {
    padding-top: 30px !important;
}

.ml5 {
    margin-left: 5px !important;
}

.mt5 {
	margin-top: 5px !important;
}

.mt10 {
	margin-top: 10px !important;
}

.mt20 {
	margin-top: 20px !important;
}

.mt35 {
	margin-top: 35px !important;
}

.table-striped {
	background-color: rgba(0, 0, 0, 0.05);
}

.table-white0 {
	background-color: rgba(0, 0, 0, 0.05);
}

.fs10 {
	font-size: 10px !important;
}

.fs12 {
	font-size: 12px !important;
}

.fs20 {
	font-size: 20px !important;
}

.fs25{
	font-size: 25px !important;
}

.fs40 {
	font-size: 40px !important;
}

.table td, .table th {
    padding: 0.25rem 0.75rem !important;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.pt14 {
	padding-top: 14px !important;
}